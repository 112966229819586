<template>
  <Interface :title="state.title" :paths="createPacketPaths" :btnLabel="btnLabel" btnAsEvent @action="submit"
    :noBtn="!hasBtnAction" :sortOptions="fieldsOptions" @sort="sort" @filter="filterSwitch" separator
    @firstSelect="firstSelect" :hasTabs="module.includes('packets')"
    :tab1="{ name: 'PAGOS', to: { path: 'packets_paid' } }" :tab2="{ name: 'A PAGAR', to: { path: 'packets_topay' } }"
    :buttonDisabled="isLoading" :perPage="isAsyncPaginate ? perPage : undefined" @perPageChanged="perPageChanged">
    <div v-if="module.includes('packets')" class="title-btn-2">
      <!--<i class="icon-btn material-icons">edit</i>-->
      <template v-if="!loadingCSV">
        <span @click="csvGetData" class="label">Exportar Excel</span>
        <div>
          <b-form-checkbox style="position: absolute; left: 0px; top: 40px; font-size: 12px; line-height: 25px"
            v-model="csvFromFilter">Gerar a partir do filtro</b-form-checkbox>
        </div>
      </template>
      <div v-else class="text-center label">
        <b-spinner class="align-middle"></b-spinner>
        <span class="pl-2">Carregando...</span>
      </div>
    </div>
    <b-table ref="table" sticky-header="100vh" style="padding-bottom: 16px" :busy="isBusy" :items="items"
      :fields="fieldsExtras" :per-page="isAsyncPaginate ? undefined : perPage"
      :current-page="isAsyncPaginate ? undefined : currentPage" :sort-by.sync="sortBy" :sort-desc="!sortDirection"
      :filter="isAsyncPaginate ? undefined : filterData" :filter-function="isAsyncPaginate ? undefined : filter"
      @filtered="onFiltered" show-empty :hover="!state.noHover" @row-clicked="onRowClicked">
      <template v-if="state.hasQty || state.hasEdit || state.hasEditItem"
        v-slot:cell()="{ value, item, field: { key } }">
        <template v-if="edit != item.id">{{ value }}</template>
        <template v-else>
          <!---->
          <template v-if="key === 'notify'">
            <b-form-select v-model="item[key]" @change="logNotifyChange(item[key])" :options="[
              { text: 'Sim', value: 1 },
              { text: 'Não', value: 0 },
            ]"></b-form-select>
          </template>
          <template v-else-if="key === 'pais'">
            <b-form-select v-model="item[key]" :options="[
              { text: 'Brasil', value: 'Brasil' },
              { text: 'Mexico', value: 'Mexico' },
              { text: 'Estados Unidos', value: 'Estados Unidos' },
            ]"></b-form-select>
          </template>
          <template v-else-if="key === 'lithium_battery' || key === 'perfumes' || key === 'cbd'">
            <b-form-select v-model="item[key]" :options="[
              { text: 'Sim', value: '1' },
              { text: 'Não', value: null },
            ]"></b-form-select>
          </template>
          <template v-else>
            <b-form-input v-if="key != 'total'" v-model="item[key]" />
          </template>
        </template>
      </template>
      <template v-slot:cell(actions)="{ item: { id }, item }">
        <div v-if="
          module.includes('packets') &&
          (!item.paid_out ||
            ($store.getters.isAdmin && item.status === 'Á enviar'))
        " class="btn-icons btn-actions">
          <i v-if="!($store.state.senders.loading)" @click="goEditPacket(item)" class="material-icons btn-icon">edit</i>
          <span class="loading-btn" v-else><b-spinner class="align-middle text-success"></b-spinner></span>
        </div>
        <div v-else-if="!module.includes('packets') && !module.includes('conferencia')" class="btn-icons btn-actions">
          <i v-if="edit !== id" :style="state.title === 'Redirecionadores'
            ? 'background-color: #ffc107'
            : 'background-color: #28a745'
            " @click="onEdit(id)" class="material-icons btn-icon">edit</i>
          <i v-else :style="state.title === 'Redirecionadores'
            ? 'background-color: #ffc107'
            : 'background-color: #28a745'
            " @click="onSave(item, id)" class="material-icons btn-icon">check</i>
        </div>
        <div v-if="module.includes('conferencia')" class="btn-icons btn-actions">
          <i @click="conferencia(item)" class="material-icons btn-icon" style="background-color: blue">fact_check</i>
        </div>
        <template v-if="state.hasBtnHov && state.hasEdit">
          <div class="btn-service">
            <i @click="toggleModal(item)" variant="warning" style="background-color: #28a745"
              class="material-icons btn-icon">add</i>
            <i @click="toggleModalTable(item)" variant="info" style="background-color: #17a2b8"
              class="material-icons btn-icon">list_alt</i>
          </div>
        </template>
        <template v-if="$store.getters.isAdmin && module === 'redirectors'">
          <i v-if="
            !item.groups.reduce(
              (v, g) => v || g.key === 'RedirectorPremium',
              false
            )
          " @click="doPremium(item)" class="material-icons btn-icon" style="background-color: #fd6a02">star</i>
          <i v-else @click="undoPremium(item)" class="material-icons btn-icon"
            style="background-color: #fd6a02">not_interested</i>
          <i @click="openCreditoModal(item)" class="material-icons btn-icon"
            style="background-color: green">attach_money</i>
        </template>
        <template v-if="module === 'redirectors'">
          <i @click="listR(item)" class="material-icons btn-icon"
            style="background-color: blue">supervised_user_circle</i>
        </template>
        <template v-if="module === 'redirectors'">
          <i @click="modalLogoRedirector(item)" class="material-icons btn-icon"
            style="background-color: purple">image</i>
        </template>
        <template v-if="module.includes('packets') && item.hasCbd">
          <i @click="getCDBs(item)" class="material-icons btn-icon" style="background-color: slateblue">assignment</i>
        </template>
        <template v-if="module.includes('packets') && item.paid_out">
          <i @click="invoice(item)" class="material-icons btn-icon" style="background-color: darkblue">receipt_long</i>
        </template>
        <template v-if="!(module.includes('packets') && item.status === 'Enviado') && !module.includes('conferencia')">
          <i @click="remove(item)" class="material-icons btn-icon" style="background-color: red">delete</i>
        </template>
        <template v-if="module.includes('packets') && item.status === 'Aguardando' && !$store.getters.isAdmin">
          <i @click="undoShipment(item)" class="material-icons btn-icon" style="background-color: blue">undo</i>
        </template>
        <!--
        <template
          v-if="module.includes('packets') && item.status === 'Aguardando' && !$store.getters.isAdmin"
        >
          <i
            @click="payCard(item)"
            class="material-icons btn-icon"
            style="background-color: green"
            >credit_card</i
          >
        </template>
        -->
      </template>

      <template v-if="state.hasQty" #cell(quantity)="key">
        <input :id="'inputqty_' + key.item.id" type="number" placeholder="Quantidade" min="1" class="form-control"
          @change="qtyChange" :value="qtySelect[key.item.id] ? qtySelect[key.item.id].qty : undefined
            " />
      </template>

      <template v-if="state.hasQty" #cell(value)="key">
        <input :id="'inputvalue_' + key.item.id" type="number" step=".01" placeholder="$ Valor" class="form-control"
          @change="valChange" :value="qtySelect[key.item.id] ? qtySelect[key.item.id].value : undefined
            " />
      </template>

      <template v-if="checkboxs || radios" #cell(selected)="row">
        <!-- Enviado -->
        <template v-if="module.includes('packets') && row.item.url">
          <div class="btn-icons btn-actions">
            <i @click="openLink(row.item)" class="material-icons btn-icon">picture_as_pdf</i>
            <!--<b-spinner v-else small label="Loading..."></b-spinner>-->
          </div>
        </template>
        <!-- Not checkbox -->
        <template v-else-if="notSetCheckbox(row.item)">
          <div></div>
        </template>
        <!-- Checkbox / Radio -->
        <template v-else>
          <div :class="classSelection">
            <div></div>
            <div :class="(isItemSelected(row.item) ? 'active ' : '') + 'fill-selected'
              "></div>
          </div>
        </template>
      </template>

      <template #cell(tracking)="key">
        <a style="color: #4cb6d2" target="_blank" :href="'https://bringerparcel.com/tracking/' + key.item.tracking">{{
          key.item.tracking }}</a>
      </template>

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="pl-2">Carregando...</strong>
        </div>
      </template>
      <template #head()="key">
        <!-- Checkbox de selecionar tudo -->
        <template v-if="key.field.key == 'selected' && checkboxs">
          <div @click="switchSelection" class="checkbox d-inline-block align-top" style="background-color: #414d55">
            <div></div>
            <div :class="(isAllRowsSelected ? 'active ' : '') + 'fill-selected'"></div>
          </div>
          <span class="pl-4">Todos</span>
        </template>
        <template v-else-if="key.field.key == 'status'">
          <div>{{ key.label }}</div>
          <b-form-select v-show="isFiltering" @input="asyncPaginate(false)" :id="'filter-' + key.field.key"
            :options="module === 'packets_topay' ? ['Aberto', 'Aguardando'] : ['Á enviar', 'Enviado']"
            v-model="filterData[key.field.key]">
            <template #first>
              <b-form-select-option :value="undefined">Sem Opção</b-form-select-option>
            </template>
          </b-form-select>
        </template>
        <template v-else-if="!['selected', 'actions'].includes(key.field.key)">
          <div>{{ key.label }}</div>
          <input v-show="isFiltering" type="text" class="form-control" :id="'filter-' + key.field.key"
            v-model="filterData[key.field.key]" @input="asyncPaginate(false)" />
        </template>
      </template>
      <template #empty>
        <div class="text-center my-2">Não há registros até o momento.</div>
      </template>
      <template #emptyfiltered>
        <div class="text-center my-2">Não há registros até o momento.</div>
      </template>
    </b-table>
    <b-modal id="service-form" v-model="modalShow" size="xl" centered>
      <template #modal-header></template>
      <ServiceForm :user="userSelected"></ServiceForm>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="service" v-model="modalTable" size="xl" centered>
      <template #modal-header></template>
      <Services :user="userSelected"></Services>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="cdb" v-model="modalCDB" size="lg" centered>
      <template #modal-header>
        <h4>Arquivos CBDs do pacote {{ packetCdbName }}</h4>
      </template>
      <template v-if="!loadingCdb">
        <ul v-if="cdbs.length" style="margin: 0">
          <li v-for="(d, i) in cdbs" :key="d.id">
            <div class="d-flex justify-content-between align-items-center">
              <a :href="d.uri" target="_blank">{{ d.uri.split('/').slice(-1)[0] }}</a>
              <i @click="deleteCbd(d.id)" class="material-icons btn-icon button-cbd">delete</i>
            </div>
            <hr v-if="i < cdbs.length - 1" style="margin-left: -57px; width: 110.1%;" />
          </li>
        </ul>
        <div v-else>
          <p>Este pacote não possui nenhum documento CBD vinculado.</p>
        </div>
      </template>
      <template v-else>
        <div class="text-center label">
          <b-spinner class="align-middle"></b-spinner>
          <span class="pl-2">Carregando ...</span>
        </div>
      </template>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="pay" v-model="modalPay" size="xl" centered>
      <template #modal-header>Pagamento</template>
      <pre style="font-size: 18px; padding-top: 24px">
    Para gerar a etiqueta é necessário realizar uma transferência para essa conta:

    Conta Corrente: 1579218-3

    Agência: 0992

    Banco: Bradesco

    Caso já tenha feito o pagamento entre em contato pelo seguinte email.: email@email.com
  </pre>
      <!-- {{ pay_selected }} -->
    </b-modal>
    <b-modal id="edit-packet" v-model="modalEdit" size="xl" centered>
      <template #modal-header>Editar Pacote</template>
      <PacketEdit :packet="item_selected_edit"></PacketEdit>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="remessa" v-model="modalRemessa" size="md" centered>
      <template #modal-header></template>
      <Remessa :codigo="remessa_cod" :total="remessa_total"></Remessa>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="remessa-limite" v-model="modalRemessaLimite" size="md" centered>
      <template #modal-header></template>
      <RemessaLimite :codigo="selectedNumbers" :total="totalSelected"></RemessaLimite>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="items" v-model="modalItems" size="lg" centered>
      <template #modal-header></template>
      <ItemResume></ItemResume>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="redirectors" v-model="modalRedirectors" size="md" centered>
      <template #modal-header></template>
      <Redirectors :user="redirectorSelected"></Redirectors>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="credito" v-model="modalCredito" size="md" centered>
      <template #modal-header>
        <b>{{ redirectorSelected.name }}</b>
      </template>
      <Credito :user="redirectorSelected"></Credito>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="logo" v-model="modalLogo" size="md" centered>
      <template #modal-header>
        <b>{{ modalLogoUser.name }}</b>
      </template>
      <div class="d-flex flex-column">
        <div class="h-separator">
          <label for="image-logo" class="title">Imagem atual:</label>
          <span></span>
        </div>
        <div class="mt-2 mb-3">
          <a v-if="modalLogoImg" target="_blank" :href="modalLogoImg">Ver imagem</a>
          <span v-else-if="modalLogoImg === null">Sem imagem</span>
          <span v-else>Carregando ...</span>
        </div>

        <div class="h-separator">
          <label for="image-logo" class="title">Subir nova imagem</label>
          <span></span>
        </div>
        <b-form-file id="image-logo" class="image-logo" v-model="imageLogo" :state="Boolean(imageLogo)"
          :browse-text="'Selecionar'" placeholder="Clique aqui selecionar uma imagem"
          drop-placeholder="Solte uma imagem aqui" accept="image/*"></b-form-file>
        <div class="d-flex flex-row justify-content-end mt-3">
          <b-button @click="uploadNewImageLogo" :disabled="!imageLogo" style="width: fit-content"
            variant="primary">Salvar</b-button>
        </div>
      </div>
      <template #modal-footer>
        <span></span>
      </template>
    </b-modal>
    <b-modal id="conferenciaModal" v-model="modalConferencia" size="lg" centered ok-only>
      <template #modal-header>
        <b>Conferência de Pacote: {{ conferenciaItem ? conferenciaItem.packet_number : '' }}</b>
      </template>
      <template v-if="conferenciaItem">
        <!-- 
        <div v-for="item in conferenciaItem.items" :key="item.id">
          <p>{{ item.description }} {{ item.quantity }} {{ item.value }}</p>
        </div>
        -->
        <b-table :items="conferenciaItem.items" class="table-conferencia" :fields="[
          {
            key: 'check',
            label: 'Verificado',
            sortable: false
          },
          {
            key: 'qty',
            label: 'Quantidade',
            sortable: true
          },
          {
            key: 'description',
            label: 'Item',
            sortable: true
          },
          {
            key: 'value',
            label: 'Valor',
            formatter: v => '$' + (v * 1).toFixed(2),
            sortable: true
          },
        ]">
          <template v-slot:cell(check)="{ item: { id } }">
            <b-form-checkbox v-model="conferenciaCheck[id]" size="lg" />
          </template>
        </b-table>
      </template>
    </b-modal>
    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" size="md" align="right" class="my-0"
      @change="asyncPaginate"></b-pagination>
  </Interface>
</template>

<style lang="stylus">
@import '../../style/main/colors.styl';
@import '../../style/main/fonts.styl';
@import '../../style/main/mixins.styl';

.table {
  color: greyLight !important;
  border-collapse: separate;
  border-spacing: 0 1em;
}

.table.b-table > thead > tr > .table-b-table-default, .table.b-table > tbody > tr > .table-b-table-default, .table.b-table > tfoot > tr > .table-b-table-default {
  background-color: light !important;
  color: black;
}

.table td, .table th, .table thead th {
  border: none;
  white-space: nowrap;
}

.table thead th {
  padding: 0 0.75rem;
  font-size: 20px;
}

.table tr {
  // background-color content-base-theme()
  background-color: primary;
  transition: 0.4s;

  &:focus {
    outline: none;
  }
}

radius = 0px;

.table tr td:first-child {
  border-top-left-radius: radius;
  border-bottom-left-radius: radius;
  width: 8%;
}

.table tr td:last-child {
  border-top-right-radius: radius;
  border-bottom-right-radius: radius;
}

.table th div {
  nowrap();
}

.table.b-table > tbody > .table-active, .table.b-table > tbody > .table-active > th, .table.b-table > tbody > .table-active > td {
  background-color: secondary !important;
}

.table.b-table tr {
  .btn-actions {
    min-width: 64px;
    display: inline;
  }

  i {
    opacity: 0;
    transition: 0.3s;
    background-color: green;
  }

  .loading-btn {
    opacity: 0;
    transition: 0.3s;
    position relative

    span {
      position absolute
      right 10px
      bottom -1px
    }
  }

  &:hover {
    i {
      opacity: 1;
    }

    .loading-btn {
      opacity: 1;
    }
  }
}

.table-conferencia {
  border-spacing: 0px 0em !important;
}

.table-conferencia td {
  text-wrap: auto;
  text-align: center;
  vertical-align: middle !important;
  color black !important;
  background-color white !important
}

.table-conferencia tr {
  background-color white !important
}

.table-conferencia th {
  color black !important
  font-weight normal !important
  font-size: 16px !important
  text-align center
}

.ec {
  align-self: flex-end;
}

.btn {
  justify-content: space-between;
  margin-left: 10px;
  float: left;
}

.btn-service {
  display: inline;
  margin-left: 0px;
}

.checkbox, .radio {
  display: block;
  width: 25px;
  height: 25px;
  background-color: light;
  // padding 20px
  border: 1px solid black;
  cursor: pointer;
  margin-top: 4px;

  .fill-selected {
    min-height: 100%;
    // border-radius: 4px
    transition: 0.2s;
    transform: scale(0.2);
  }

  .active {
    background-color: green;
    width: auto;
    height: auto;
    transform: scale(1);
  }
}

.radio {
  &, .fill-selected {
    border-radius: 50%;
  }
}

#remessa___BV_modal_header_, #remessa___BV_modal_footer_ {
  display: none;
}

#remessa___BV_modal_content_ {
  background-color: light;
  border-radius: 8px;
}

.title-btn-2 {
  color: white;
  margin-top: -4.5rem;
  position: absolute;
  top: -166px;
  right: 179px;
  font-family: Calibri;
  display: flex;
  align-items: center;

  .label {
    padding: 6px 30px;
    background-color: blue;
    cursor: pointer;
    height: 38px;
    border-radius: 5px;

    &:hover {
      background-color: alpha(primary, 0.8);
      transition: 0.2s;
    }
  }

  .spinner-border {
    width: 1.25rem !important;
    height: 1.25rem !important;
  }

  span, .icon-btn {
    vertical-align: middle;
    no-select();
  }
}

.button-cbd {
  background-color: #dc3545
  font-size: 20px
  padding: 4px
  border-radius: 4px

  &:hover {
    background-color darken(#dc3545, 20%)
  }
}

.h-separator {
  display: flex;

  .title {
    padding: 0px 10px;
    font-size: 14px;
    color: light;
    font-weight: bold;
    border-radius: 1rem;
    background: primary;
  }

  span {
    align-self: center;
  }

  span:last-child {
    border: 1px dashed primary;
    height: 1px;
  }

  span:last-child {
    flex-grow: 1;
  }
}
</style>

<style>
.image-logo .custom-file-label {
  line-height: 0.8;
}
</style>

<script>
/* eslint-disable */
import Interface from "@/components/Dashboard/Interface";
import ServiceForm from "../../components/Modals/ServiceForm.vue";
import PacketEdit from "../../components/Modals/PacketEdit.vue";
import Remessa from "../../components/Modals/Remessa.vue";
import RemessaLimite from "../../components/Modals/RemessaLimite.vue";
import ItemResume from "../../components/Modals/ItemResume.vue";
import Redirectors from "../../components/Modals/RedirectorsMenu.vue";
import Credito from "../../components/Modals/Credito.vue";
import Services from "../Dashboard/Services.vue";
import api from "@/services";
import { toast, packetToSave, userToExtraFields, normalizeStr, toOptions, errorFormater } from "@/utils";

export default {
  components: {
    Interface,
    ServiceForm,
    Services,
    PacketEdit,
    Remessa,
    RemessaLimite,
    ItemResume,
    Redirectors,
    Credito
  },
  data: () => ({
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    sortBy: "",
    sortDirection: true,
    isFiltering: false,
    filterData: {},
    qtySelect: {},
    edit: null,
    modalShow: false,
    modalTable: false,
    modalItems: false,
    isLoading: false,
    loadingCSV: false,
    modalPay: false,
    modalRemessa: false,
    modalRemessaLimite: false,
    modalRedirectors: false,
    modalCredito: false,
    redirectorSelected: null,
    remessa_cod: "",
    remessa_total: 0,
    pay_selected: null,
    item_selected_edit: null,
    modalEdit: false, // packet
    primaryFieldKey: "id",
    selected: [],
    userSelected: {},
    cdbs: [],
    loadingCdb: false,
    modalCDB: false,
    packetCdbName: null,
    csvFromFilter: true,
    modalLogo: false,
    modalLogoImg: undefined,
    modalLogoUser: null,
    modalConferencia: false,
    conferenciaItem: null,
    imageLogo: null,
    conferenciaCheck: {}
  }),
  props: {
    module: {
      type: String,
      required: true,
    },
  },
  watch: {
    filterData: {
      handler(value) {
        Object.keys(value).forEach((key) => {
          if (value[key] === "") {
            // console.log("empty: " + value);
            delete value[key];
          }
        });
      },
      deep: true,
    },
    selected: {
      handler() {
        // quick dirty fix to button overlap
        if (document.getElementsByClassName("title-btn-2").length)
          setTimeout(
            () =>
            (document.getElementsByClassName("title-btn-2")[0].style.right =
              document.getElementsByClassName("title-btn")?.[0].offsetWidth +
              25 +
              "px"),
            1
          );
      },
      deep: true,
    },
    //'$store.state.items.itens': {
    //  handler(value) {
    //    if(this.state.hasQty) {
    //      this.qtySelect = { ...(value.map(i => ({ [i.id]: ({qty: undefined, value: undefined}) }) )), ...this.qtySelect }
    //    }
    //  },
    //},
  },
  created() {
    this.totalRows = this.items.length;

    if (["home.sender", "home.receiver"].includes(this.module)) {
      this.selected = [
        this.$store.state.packageSave[this.module.split(".")[1]],
      ].reduce((a, b) => (b ? [...a, b] : a), []);
    }

    if (this.module === "home.items") {
      this.qtySelect = this.$store.state.packageSave.items || {};
      // this.qtySelect = { ...(this.items.map(i => ({ [i.id]: ({qty: undefined, value: undefined}) }) )), ...this.qtySelect }

      this.sortBy = 'value'
      this.sortDirection = false
    }

    if (this.isAsyncPaginate) this.asyncPaginate(1);
  },
  computed: {
    isBusy() {
      return this.externalModule
        ? this.externalModule.loading
        : this.state.loading;
    },
    state() {
      let path = this.module.split(".");

      let state = this.$store.state;

      path.forEach((e) => {
        state = state[e];
      });

      return state;
    },
    externalModule() {
      return this.state.externalModule
        ? this.$store.state[this.state.externalModule]
        : null;
    },
    fields() {
      if (this.state.dynamic) {
        return this.items
          .map((item) => Object.keys(item))
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index);
      }

      let baseFields =
        this.state.fields ||
        this.$store?.state[this.state.externalModule].fields;

      // add extra fields to table
      if (this.state.hasExtraField) {
        baseFields.push(
          ...(
            this.$store.state.extrafield.groups[this.state.groupName] || []
          ).map((e) => ({
            // format to table field
            key: e.key,
            label: e.description,
            formatter: e.key.includes('credito') ? (p => (p * 1).toFixed(2)) : undefined
          }))
        );
        // console.log(baseFields);
      }

      // Add cargo to redirector when admin
      if (this.$store.getters.isAdmin && this.module === "redirectors") {
        baseFields = [
          ...baseFields,
          {
            key: "cargo",
            label: "Cargo",
            formatter: (value, key, item) => {
              return item.groups.reduce(
                (v, g) => v || g.key === "RedirectorPremium",
                false
              )
                ? "Premium"
                : "Comum";
            },
          },
        ];
      }

      return this.state.hasQty
        ? [
          { key: "quantity", label: "Quantidade" },
          { key: "value", label: "Valor unitário" },
          ...baseFields,
          { key: "total", label: "Valor Total" },
          { key: "actions", label: "" },
        ]
        : this.state.hasEdit || this.state.hasEditItem
          ? [...baseFields, { key: "actions", label: "" }]
          : this.state.hasLink
            ? [{ key: "actions", label: "" }, ...baseFields]
            : [...baseFields];
    },

    fieldsExtras() {
      let f =
        this.$store.state.user.name === "Super Admin" &&
          this.state.itemsKey.includes("packets")
          ? [
            ...this.fields,
            { key: "redirector_name", label: "Nome redirecionador" },
          ]
          : [...this.fields];

      if (this.checkboxs || this.radios) {
        f.unshift({ key: "selected", label: " " });
      }
      return f;
    },

    items() {
      let res = this.externalModule
        ? this.externalModule[this.state.itemsKey]
        : this.state[this.state.itemsKey];

      if (!this.state.hasEdit && this.module.includes("items")) {
        res = res.map((item) => ({
          ...item,
          quantity: this.qtySelect[item.id]?.qty || "",
          value: this.qtySelect[item.id]?.value || "",
          total: (
            (this.qtySelect[item.id]?.qty || "") *
            (this.qtySelect[item.id]?.value || "")
          ).toFixed(2),
        }));
      }

      res = this.state.hasExtraField ? res.map(userToExtraFields) : res;
      res = this.state.filter ? res.filter(this.state.filter) : res;

      return res;
    },
    // stateItems
    fieldsOptions() {
      return (
        typeof this.fieldsExtras[0] !== "string"
          ? toOptions(this.fieldsExtras, { value: "key", text: "label" }) // manual fields
          : this.fieldsExtras
      ).filter((f) => !!f.text.trim()); // dynamic fields
    },
    hasBtnAction() {
      return !!this.state.linkTo || !!this.state.btnAction; // || this.state.routes.find((r) => r.key === "add") === undefined;
    },
    linkTo() {
      return this.state.linkTo;
    },
    addParams() {
      return {
        item: null,
        action: this.state.routes.find((r) => r.key === "add"),
        module: this.module,
      };
    },
    isAllRowsSelected() {
      return (
        this.items.filter((item) => !this.notSetCheckbox(item)).length ===
        this.selected.length && this.selected.length
      );
    },
    checkboxs() {
      return this.state.checkboxs;
    },
    radios() {
      return this.state.radios;
    },
    classSelection() {
      return this.checkboxs ? "checkbox" : this.radios ? "radio" : "";
    },
    totalSelected() {
      return this.selected.reduce(
        (total, item) => total + item.internal_value * 1,
        0
      );
    },
    btnLabel() {
      return this.$store.getters.isAdmin && this.module === "packets_topay"
        ? this.state.btnLabelAdmin +
        (this.selected.length > 0 && this.module === "packets_topay"
          ? " ($ " + this.totalSelected.toFixed(2) + ")"
          : "")
        : this.state.btnLabel +
        (this.selected.length > 0 && this.module === "packets_topay"
          ? " ($ " + this.totalSelected.toFixed(2) + ")"
          : "");
    },
    createPacketPaths() {
      return this.state.createPacket
        ? [
          { name: "Remetente", route: "sendbox.sender" },
          { name: "Destinatário", route: "sendbox.receiver" },
          { name: "Detalhes do Pacote", route: "sendbox.package" },
          { name: "Itens", route: "sendbox.items" },
          { name: "Resumo", route: "sendbox.resume" },
        ]
        : undefined;
    },
    isAsyncPaginate() {
      return this.externalModule
        ? this.externalModule.async_paginate
        : this.state.async_paginate;
    },
    selectedNumbers() {
      return (this.selected || []).map(p => p?.packet_number || '').join(', ')
    }
  },
  methods: {
logNotifyChange(value) {
  console.log('Valor de notify alterado:', value);
  console.log('item atual:', this.items); // Adicionando log para verificar o estado dos itens
    },
    submit(fromRemessa) {
      let isAdminInPaidOut =
        this.$store.getters.isAdmin && this.module === "packets_topay";

      // GERAR REMESSA
      if (this.module === "packets_topay" && !isAdminInPaidOut && fromRemessa !== 1) {
        if (this.selected.length === 0) {
          toast(
            this,
            "info",
            "Nenhum Pacote selecionado",
            "Marque um ou mais pacotes para continuar"
          );
          return;
        }
        if ((this.selected || []).length > 1 && this.totalSelected > (this.$store.state.user.credito_disponivel * 1)) {
          this.modalRemessaLimite = true;
          return;
        }
        this.isLoading = true;
        api.post(this.state.btnAction, {
          send_ids: this.selected.map((p) => p.send_id),
        })

          .then(
            (response) => {
              // console.log(response);
              if (response.status >= 200 && response.status <= 299) {
                this.selected.forEach((item) => {
                  this.$store.dispatch("packets/edit", {
                    ...item,
                    status: "Aguardando",
                    code_shipment: response.data?.shipment.code,
                  });
                });

                this.submit(1)

                this.remessa_cod = response.data?.shipment.code;
                this.remessa_total = this.totalSelected;
                // this.modalRemessa = true;
              }
              // this.selected = [];
              // this.isLoading = false;
            },
            (error) => {
              toast(
                this,
                "danger",
                "Erro",
                errorFormater(error),
                JSON.stringify(error.response.data)
              );
              this.selected = [];
              this.isLoading = false;
              return Promise.reject(error);
            }
          );
        return;
      }

      // GERAR ETIQUETA OU PAGAMENTO (ADMIN)
      if (this.module === "packets_paid" || isAdminInPaidOut || (fromRemessa === 1)) {
        if (this.selected.length === 0) {
          toast(
            this,
            "info",
            "Nenhum Pacote selecionado",
            "Marque um ou mais pacotes para continuar"
          );
          return;
        }
        this.isLoading = true;
        let hadErrors = false;
        let finished = this.selected.length;
        let callWhenFinish = () => {
          if (finished === 0) {
            if (!hadErrors && (isAdminInPaidOut || (fromRemessa === 1))) {
              this.selected.forEach(s => {
                // console.log(s.tenant_id, s.value, s.internal_value)
                if (s.tenant_id === this.$store.state.user.id) {
                  this.$store.dispatch("updateCredito", { disponivel: (this.$store.state.user.credito_disponivel * 1) - (s.internal_value * 1), utilizado: (this.$store.state.user.credito_utilizado * 1) + (s.internal_value * 1) });
                }
                else {
                  const u = this.$store.state.redirectors.redirectors.find(u => u.id === s.tenant_id)
                  u['extra_fields'] = u['extra_fields'].map(e => e.key === 'credito_disponivel' ? { ...e, value: (e.value * 1) - s.internal_value } : e)
                  this.$store.dispatch("redirectors/edit", u);
                }
              })
            }
            this.selected = [];
            this.isLoading = false;
            if (!hadErrors) {
              toast(
                this,
                "success",
                "Sucesso",
                "Todos os Pacotes foram " +
                (isAdminInPaidOut || (fromRemessa === 1) ? "pagos" : "enviados") +
                " com sucesso"
              );
            }
          }
        };
        this.selected.forEach((item) => {
          api
            .post(
              isAdminInPaidOut || (fromRemessa === 1)
                ? this.state.btnActionAdmin
                : this.state.btnAction,
              {
                id: item.id,
              }
            )
            .then(
              (response) => {
                if (response.status >= 200 && response.status <= 299) {
                  if (isAdminInPaidOut || (fromRemessa === 1)) {
                    this.$store.dispatch('packets/remove', item.id)
                  }
                  else {
                    this.$store.dispatch(
                      "packets/edit",
                      {
                        ...item,
                        url: response.data.url,
                        tracking: response.data.tracking,
                        status: "Enviado",
                      }
                    );
                  }
                  //toast(this, "success", "Etiqueta", "Etiqueta Gerada com Sucesso");
                }
                finished--;
                callWhenFinish();
              },
              (error) => {
                hadErrors = true;
                if (error.response.status === 401 || error.response.status === 400) {
                  if (fromRemessa === 1 && error.response.status === 401) {
                    this.modalRemessa = true;
                  }
                  else {
                    if (error.response.status === 401) toast(this, 'info', 'Redirecionador Sem crédito', 'Crédito insuficiente\nPara gerar a etiqueta é necessário que o Redirecionador tenha crédito disponível')
                    if (error.response.status === 400) toast(this, 'info', 'Pacote incompleto', 'Pacote com dados incompleto, edite e preencha todos os campos em resumo para continuar')
                  }
                  finished--;
                  callWhenFinish();
                  return Promise.reject(error);
                }
                toast(
                  this,
                  "danger",
                  isAdminInPaidOut || (fromRemessa === 1)
                    ? "Pacote não foi pago"
                    : "Etiqueta não foi gerada (" + item.packet_number + ")",
                  errorFormater(error),
                  JSON.stringify(error.response.data)
                );
                finished--;
                callWhenFinish();
                return Promise.reject(error);
              }
            );
        });
        return;
      }

      // ITEM
      if (this.state.hasQty) {
        if (
          Object.keys(this.qtySelect).reduce(
            (value, id) =>
              value ||
              (this.qtySelect[id]?.qty > 0 && this.qtySelect[id]?.value > 0),
            false
          )
        ) {
          this.modalItems = true;
          // this.$router.replace({ path: "/sendbox/resume" });
        } else {
          toast(
            this,
            "danger",
            "Itens não linkados",
            "Selecione algum item para linkar com sucesso ao pacote"
          );
        }
        return;
      }
      if (this.radios) {
        if (this.selected.length > 0) {
          this.$router.replace({ name: this.linkTo });
        } else {
          toast(
            this,
            "info",
            "Valor não selecionado",
            "Clique em uma linha da tabela para selecionar um valor e continuar"
          );
        }
      } else {
        this.$router.replace({ name: this.linkTo });
      }
    },
    undoShipment(item) {
      if (!confirm('Tem certeza que deseja voltar o status do pacote para Aberto? O pacote será desassociado da remessa atual.')) return
      api.post('packet/shipment_undo', { send_id: item.send_id }).then(
        () => {
          this.$store.dispatch(
            "packets/edit",
            {
              ...item,
              code_shipment: '',
              status: "Aberto",
            }
          );
        },
        error => {
          toast(
            this,
            "danger",
            "Erro",
            errorFormater(error),
            JSON.stringify(error.response.data)
          );
          return Promise.reject(error);
        })
    },
    itemSelectedIndex(item) {
      return this.selected.findIndex(
        (o) => o[this.primaryFieldKey] === item[this.primaryFieldKey]
      );
    },
    isItemSelected(item) {
      return !!(this.itemSelectedIndex(item) + 1);
    },
    switchSelection() {
      this.emitSelection(
        this.isAllRowsSelected
          ? []
          : this.items.filter((item) => !this.notSetCheckbox(item))
      );
    },
    emitSelection(v) {
      //this.$emit("onChange", v);
      this.selected = [...v];
    },
    onRowClicked(item) {
      if (!this.checkboxs && !this.radios) return;

      const i = this.itemSelectedIndex(item);

      if (this.module.includes("packets")) {
        if (this.notSetCheckbox(item)) return;
      }

      this.emitSelection(
        this.checkboxs
          ? i === -1
            ? [...this.selected, item] // Insert
            : this.selected.filter((v, i2) => i - i2) // Remove
          : [item]
      );
      this.afterSelected();
    },
    notSetCheckbox(item) {
      return (
        item.url ||
        ((item.status === "Aguardando") !== this.$store.getters.isAdmin &&
          this.module == "packets_topay")
      );
    },
    afterSelected() {
      // CREATE PACKAGE (SENDER, RECEIVER)
      let n = this.$route.name?.split(".")[1];
      if (["sender", "receiver"].includes(n)) {
        this.$store.dispatch("packageSave", {
          ...this.$store.state.packageSave,
          [n]: this.selected[0],
        });
        return;
      }
    },
    qtyChange(e) {
      this.qtySelect = {
        ...this.qtySelect,
        [e.target.id.split("_")[1]]: {
          qty: e.target.value,
          value: this.qtySelect[e.target.id.split("_")[1]]?.value || "",
        },
      };

      this.$store.dispatch("packageSave", {
        ...this.$store.state.packageSave,
        ["items"]: Object.keys(this.qtySelect).reduce(
          (obj, id) =>
            Object.assign(
              {},
              this.qtySelect[id]?.qty > 0 && this.qtySelect[id]?.value > 0
                ? {
                  [id]: {
                    qty: this.qtySelect[id].qty,
                    value: this.qtySelect[id].value,
                  },
                }
                : {},
              obj
            ),
          {}
        ),
      });
    },
    valChange(e) {
      this.qtySelect = {
        ...this.qtySelect,
        [e.target.id.split("_")[1]]: {
          value: e.target.value,
          qty: this.qtySelect[e.target.id.split("_")[1]]?.qty || "",
        },
      };

      this.$store.dispatch("packageSave", {
        ...this.$store.state.packageSave,
        ["items"]: Object.keys(this.qtySelect).reduce(
          (obj, id) =>
            Object.assign(
              {},
              this.qtySelect[id]?.qty > 0 && this.qtySelect[id]?.value > 0
                ? {
                  [id]: {
                    qty: this.qtySelect[id].qty,
                    value: this.qtySelect[id].value,
                  },
                }
                : {},
              obj
            ),
          {}
        ),
      });
    },
    sort(key, direction) {
      this.sortBy = key;
      this.sortDirection = direction;
      // this.asyncPaginate(1);
    },
    filterSwitch(value) {
      this.isFiltering = value;
      if (!value) this.filterData = {};
      if (this.isAsyncPaginate && !value) this.asyncPaginate(1);
    },
    filter(item, filters) {
      if (!this.isFiltering || Object.keys(filters).length === 0) {
        return true;
      }

      for (const key of Object.keys(filters)) {
        if (
          item[key] !== undefined &&
          normalizeStr(item[key].toString()).includes(
            normalizeStr(filters[key])
          )
        ) {
          return true;
        }
      }

      return false;
    },
    onFiltered(filteredItems) {
      if (!this.isAsyncPaginate) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      }
    },
    asyncPaginate(byPaginator) {
      if (!this.isAsyncPaginate) return;

      const parameters = {
        page: byPaginator || 1,
        per_page: this.perPage,
        ...(this.isFiltering ? this.filterData : {}),
        /*order_by: (this.sortBy || ''), order_by_direction: this.sortDirection ? 'asc' : 'desc',*/ ...(this
          .state?.parameters || {}),
        ...(this.externalModule?.parameters || {}),
      };

      const getParameters =
        "?" +
        Object.keys(parameters)
          .map((k) => k + "=" + parameters[k])
          .join("&");

      const info = this.externalModule
        ? this.externalModule.get
        : this.state.get;

      const url = info.urn + getParameters;

      const moduleKey = this.externalModule
        ? this.state.externalModule
        : this.module;

      this.$store.dispatch(moduleKey + "/reset");

      api.get(url).then(
        (res) => {
          if (res.status === 200) {
            this.$store.dispatch(moduleKey + "/init", res.data[info.response]);
            this.totalRows = res.data.total;
            this.perPage = res.data.per_page * 1;
            this.currentPage = res.data.current_page;
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Erro",
            errorFormater(error),
            JSON.stringify(error.response.data)
          );
          return Promise.reject(error);
        }
      );
    },
    perPageChanged(value) {
      this.perPage = value
      console.log(value)
      if (this.isAsyncPaginate) this.asyncPaginate(1)
    },
    onEdit(id) {
      this.edit = this.edit !== id ? id : null;
    },
    onSave(item, id) {
      console.log("Método onSave chamado com item:", item);
      this.edit = this.edit !== item ? item : null;

      for (const [key, value] of Object.entries(item)) {
        item[key] = (value === 0 || value) ? value : null; // Ajustando para manter 0 como valor válido
      }

      let form = item;
      console.log("Conteúdo do form antes de enviar:", form);

      if (this.state.hasEdit) {
        form = Object.assign({}, form, {
          //group_id: form.groups[0].id,
          user_id: form.id,
        });
      }

      let m = this.module.split(".");
      m = m[m.length - 1];

      if (m[m.length - 1] === "s") {
        m = m.substring(0, m.length - 1);
      }

      [
        "remember_token",
        "tenant_id",
        "deleted_at",
        "updated_at",
        "created_at",
      ].forEach((e) => delete form[e]);

      console.log(form);
      api
        .post(this.state.hasEdit ? "auth/edit" : "item/update/" + id, form)
        .then(
          (response) => {
            // console.log(response);
            if (response.status === 200) {
              this.$store.dispatch(
                this.state.hasEdit ? m + "s/edit" : "itens/edit",
                this.state.hasEdit ? form : response.data.item
              );
              toast(
                this,
                "success",
                !this.state.hasEdit ? "Item atualizado" : "Usuário atualizado",
                "Nenhum problema foi encontrado."
              );
            }
          },
          (error) => {
            toast(
              this,
              "danger",
              !this.state.hasEdit
                ? "Item não pode ser atualizado"
                : "Usuário não pode ser atualizado",
              "Problemas encontrados" +
              (error.response.status === 422
                ? " => " + error.response.data.errors
                : "")
            );
            return Promise.reject(error);
          }
        );
    },
    doPremium(user) {
      api.post("/user/redirectorPremium", { id: user.id }).then(
        (response) => {
          // console.log(response);
          if (response.status === 200) {
            this.$store.dispatch("redirectors/edit", response.data.user);
            toast(
              this,
              "success",
              "Redirecionador Premium",
              "Redirecionador " +
              response.data.user.name +
              " passou para premium"
            );
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Erro",
            errorFormater(error),
            JSON.stringify(error.response.data)
          );
          return Promise.reject(error);
        }
      );
    },
    listR(user) {
      this.modalRedirectors = true;
      this.redirectorSelected = user;
    },
    openCreditoModal(user) {
      this.modalCredito = true;
      this.redirectorSelected = user;
    },
    undoPremium(user) {
      api.post("/user/redirectorPremiumRemove", { id: user.id }).then(
        (response) => {
          // console.log(response);
          if (response.status === 200) {
            this.$store.dispatch("redirectors/edit", response.data.user);
            toast(
              this,
              "success",
              "Redirecionador Premium",
              "Redirecionador " +
              response.data.user.name +
              " não é mais premium"
            );
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Erro",
            errorFormater(error),
            JSON.stringify(error.response.data)
          );
          return Promise.reject(error);
        }
      );
    },
    remove(item) {
      if (
        !confirm(
          "Tem certeza que deseja deletar? ATENÇÃO: deletar um dado pode resultar na perda de outros que dependem dele"
        )
      ) {
        return;
      }

      const urn =
        {
          packets_paid: "packet/delete",
          packets_topay: "packet/delete",
          redirectors: "user/delete",
          senders: "user/delete",
          receivers: "user/delete",
          itens: "item/delete",
          "home.items": "item/delete",
        }[this.module] +
        "/" +
        item.id;

      api.delete(urn).then(
        (response) => {
          // console.log(response);
          if (response.status >= 200 && response.status <= 300) {
            this.$store.dispatch(
              (this.module.includes("packets") ? "packets" : (this.module === 'home.items' ? 'itens' : this.module)) +
              "/remove",
              item.id
            );
            toast(this, "success", "Deletar", "Deletado com sucesso");
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Erro",
            errorFormater(error),
            JSON.stringify(error.response.data)
          );
          return Promise.reject(error);
        }
      );
    },
    openLink(item) {
      this.pay_selected = item;

      // if (!item.paid_out) {
      //   this.modalPay = true;
      //   return;
      // }

      if (item.url) {
        window.open(item.url, "_blank");
      }
    },
    goEditPacket(item) {
      this.$store.dispatch(
        "packageSave",
        packetToSave(this.$store.state, item)
      );
      this.$router.push({ path: "sendbox/resume" });
    },
    payCard(item) {
      this.$router.push({ name: "Payment", params: { items: [{ value: item.internal_value, name: 'Pacote ' + item.packet_number + ' (Remessa: ' + item.code_shipment + ')' }] } })
    },
    toggleModal(item) {
      this.userSelected = item;
      this.modalShow = !this.modalShow;
    },

    toggleModalTable(item) {
      this.userSelected = item;
      this.modalTable = !this.modalTable;
    },

    toggleModalItems() {
      this.modalItems = !this.modalItems;
    },

    firstSelect() {
      this.isFiltering = false;
      this.filterData = {};
      this.sortBy = "";
      // console.log(this.$refs.table.$el.querySelectorAll("tr")[1]);
      let self = this;
      setTimeout(function () {
        self.$refs.table.$el.querySelectorAll("tr")[1].click();
      }, 1500);
    },
    csvGetData() {
      if (this.loadingCSV) return;
      if (this.csvFromFilter) return this.csv(this.items);
      this.loadingCSV = true;
      api.get("packet/index").then(
        (res) => {
          if (res.status === 200) {
            this.csv(res.data.packets);
          }
        },
        (error) => {
          toast(
            this,
            "danger",
            "Erro",
            errorFormater(error),
            JSON.stringify(error.response.data)
          );
          return Promise.reject(error);
        }
      );
    },
    csv(items) {
      const fields =
        "status, remessa, pagamento, valor_xprex, número_pacote, serviço_pacote, código_de_rastreio, modalidade_pacote, peso, destino, cep, nome_remetente, nome_destinatário, email_destinatário, telefone_destinatário, data_de_criação, valor_declarado, última_atualização, nome_redirecionador";
      const data = items.reduce(
        (file, line) =>
          file +
          line.status +
          ", " +
          line.code_shipment +
          ", " +
          (line.paid_out || "não pago") +
          ", " +
          line.internal_value +
          ", " +
          line.packet_number +
          ", " +
          line.service +
          ", " +
          (line.tracking || "não gerado") +
          ", " +
          line.tax_modality +
          ", " +
          line.weight +
          ", " +
          line.destino +
          ", " +
          line.zip +
          ", " +
          line.sender_name +
          ", " +
          line.receiver_name +
          ", " +
          line.receiver_email +
          ", " +
          line.receiver_telephone +
          ", " +
          line.created_at +
          ", " +
          line.value +
          ", " +
          line.updated +
          ", " +
          (line.redirector_name || "") +
          "\n",
        fields + "\n"
      );
      // console.log(data);

      const e = document.createElement("a");
      e.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(data)
      );
      e.setAttribute(
        "download",
        "packets-" +
        (this.module === "packets_topay" ? "topay" : "paid") +
        "-" +
        new Date().toJSON().slice(0, 10).replace(/-/g, "/") +
        ".csv"
      );

      e.style.display = "none";
      document.body.appendChild(e);

      e.click();

      document.body.removeChild(e);

      this.loadingCSV = false;
    },
    getCDBs(packet) {
      this.modalCDB = true
      this.packetCdbName = packet.packet_number
      this.cdbs = []
      this.loadingCdb = true
      api.get(`packet/cdb?packet_id=${packet?.id}`).then(
        res => {
          this.cdbs = res.data
          this.loadingCdb = false
        }, error => {
          this.cdbs = []
          this.loadingCdb = false
          return Promise.reject(error);
        }
      )
    },
    deleteCbd(id) {
      if (!confirm('Você tem certeza que deseja deletar este documento?')) return
      api.delete(`packet/cdb/${id}`).then(
        () => {
          this.cdbs = this.cdbs.filter(c => c.id !== id)
        }, error => {
          this.cdbs = this.cdbs.filter(c => c.id !== id)
          return Promise.reject(error);
        }
      )
    },
    invoice(item) {
      const uri = api.defaults.baseURL + '/' + 'invoice/' + item?.id
      const e = document.createElement('a')
      e.setAttribute('href', uri)
      e.setAttribute('download', 'invoice-' + item.packet_number + '.pdf')
      e.style.display = 'none'
      document.body.appendChild(e)
      e.click()
      document.body.removeChild(e)
      /*
      api.get('invoice/' + item?.id).then(
        res => {
          console.log(res.data)
        },
        e => Promise.reject(e)
      )
      */
    },
    modalLogoRedirector(user) {
      this.modalLogo = true
      this.modalLogoUser = user
      this.modalLogoImg = undefined
      this.imageLogo = null
      console.log(user)
      api.get('user/redirector-logo/' + user.id).then(res => {
        this.modalLogoImg = res?.data?.[0]?.url || null
      })
    },
    uploadNewImageLogo() {
      if (!this.imageLogo) return
      const formData = new FormData()
      formData.append('user_id', this.modalLogoUser.id)
      formData.append('image', this.imageLogo)
      api({ method: 'POST', url: 'user/redirector-logo', data: formData, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(
          () => {
            toast(this, "success", "Imagem Salva", "Nova imagem salva com sucesso");
            this.modalLogoImg = undefined
            api.get('user/redirector-logo/' + this.modalLogoUser.id).then(res => {
              this.modalLogoImg = res?.data?.[0]?.url || null
            })
          },
          e => {
            toast(this, "danger", "Erro", errorFormater(e), JSON.stringify(e.response.data));
            return Promise.reject(e);
          }
        )
    },
    conferencia(p) {
      this.conferenciaItem = { ...p, items: p.items.map(o => ({ ...this.$store.state.itens.itens.find(item => item.id === o.item_id), qty: o.quantity, value: o.value })) }
      this.conferenciaCheck = {}
      this.modalConferencia = true
    }
  },
};
</script>
